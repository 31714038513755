.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 1em;
  width: 100%;
  background-color: #ffaf00;
  font-family: "Roboto";
  color: #ffffff;
  font-size: 1em;
}
